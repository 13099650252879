import React, {
  CSSProperties,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import classNames from 'classnames';
import { Table, notification, Button, Modal, Dropdown } from 'antd';
import { Credit } from '@prisma/client';

import { useAntPagination } from './hooks/useAntPagination';
import { getColumns } from './models/columns.constant';
import {
  selectCredits,
  setDetailCredit,
} from '../../../store/reducers/user/creditsReducer';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  Company,
  CompanyType,
} from '../../../structure/models/company/company';
import { limitOfNumbers } from '../../../helpers/limitOfNumbers';
import { selectUserAccountData } from '../../../store/reducers/user/userReducer';
import { fetchCompanyInfo } from '../../../API/company';
import { BalanceContext } from '../../../contexts/balance.context';
import { useGetContactListQuery } from '../../../API';
import { Contact } from '../ContactList';
import { fetchUpdateCreditFacilitator } from '../../../API/credits';
import { TABLES } from '../../../constants/tables';
import { compareCreditsByUpdatedAt } from '../../../helpers/sort';
import { ViewElement } from '../ViewElement/ViewElement';

import './UserBalanceStyle.scss';

export enum Action {
  Assign = 'assign',
  Unassign = 'unassign',
}

interface Props {
  company: Company;
  className?: string;
  style?: CSSProperties | undefined;
}

export const UserBalance: React.FC<Props> = ({ company, className, style }) => {
  const [selectedRows, setSelectedRows] = useState<Credit[]>([]);

  const [modal, contextHolder] = Modal.useModal();

  const { isOpened, setIsOpened } = useContext(BalanceContext);
  const { data } = useGetContactListQuery();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const credits = useAppSelector(selectCredits);
  const account = useAppSelector(selectUserAccountData);

  const { currentPage, limit, handlePageChange } = useAntPagination(
    TABLES.USER_BALANCE,
    10,
  );

  const sortedCredits = useMemo(
    () => [...credits].sort(compareCreditsByUpdatedAt),
    [credits],
  );

  const isFacilitator = useMemo(
    () => company?.type === CompanyType.Facilitator,
    [company],
  );

  const balance = useMemo(
    () => (account?.balance ? limitOfNumbers(account.balance) : 0),
    [account],
  );

  const facilitatorsOptions = useMemo(
    () =>
      data
        ?.filter(
          (contact: Contact) => contact.to.type === CompanyType.Facilitator,
        )
        .map((contact: Contact) => ({
          key: contact.to.id,
          label: contact.to.name,
        })),
    [data],
  );

  if (!account) {
    dispatch(fetchCompanyInfo());
  }

  const toggleCollapse = () => setIsOpened(!isOpened);

  const handleActionClick = useCallback(
    async (action: Action, facilitatorId?: string) => {
      if (!selectedRows.length) {
        notification.error({
          message: 'Please select BCUs',
        });
        return;
      }

      try {
        for (const credit of selectedRows) {
          await dispatch(
            fetchUpdateCreditFacilitator(credit.id, action, facilitatorId),
          );
        }

        notification.success({
          message: 'Success',
        });
      } catch (error: any) {
        notification.error({
          message: 'Error',
          description: error.message,
        });
      }
    },
    [selectedRows],
  );

  const handleUnassignClick = useCallback(() => {
    modal.confirm({
      title: 'Unassign Facilitator',
      icon: null,
      content:
        'Selected BCUs will be unassigned from any Facilitator. Do you want to proceed?',
      onOk: () => {
        handleActionClick(Action.Unassign);
        setSelectedRows([]);
      },
      okText: 'Yes',
    });
  }, [handleActionClick]);

  const handleAssignClick = useCallback(
    (facilitatorId: string) => {
      const creditsWithFacilitators = selectedRows.filter(
        (credit: Credit) => credit.facilitatorId,
      ).length;

      if (creditsWithFacilitators) {
        modal.confirm({
          title: 'Assign Facilitator',
          content:
            'One or more BCUs are already assigned to the Facilitator. If you proceed, the assignment will be overwritten by the selected Facilitator',
          onOk: () => handleActionClick(Action.Assign, facilitatorId),
          okText: 'Assign',
          cancelText: 'Cancel',
        });
      } else {
        handleActionClick(Action.Assign, facilitatorId);
      }

      setSelectedRows([]);
    },
    [credits, selectedRows],
  );

  const onTitleClick = (item: any) => {
    dispatch(setDetailCredit(item));
    navigate(`/bcu/${item.id}/bcu-info`);
  };

  const columns = useMemo(
    () => getColumns(onTitleClick, isFacilitator),
    [onTitleClick, isFacilitator],
  );

  return (
    <div className={classNames('user-balance', className)} style={style}>
      <div
        className={classNames('user-balance__header', {
          opened: isOpened,
        })}
        onClick={toggleCollapse}
        aria-controls="user-balance-credits"
        aria-expanded={isOpened}>
        <div className="user-balance__header__info">
          <ViewElement label="Company" description={company.name} />
          <ViewElement
            label="Total Balance"
            description={`${balance.toLocaleString()} BCU`}
          />
        </div>
        <div className="user-balance__header__arrow">
          <span className="material-icons-outlined">
            {`keyboard_arrow_${isOpened ? 'up' : 'down'}`}
          </span>
        </div>
      </div>
      <Collapse className="user-balance__body" in={isOpened}>
        <div id="user-balance-credits" className="user-balance-credits">
          <Table
            columns={columns}
            dataSource={sortedCredits}
            rowSelection={
              isFacilitator
                ? undefined
                : {
                    type: 'checkbox',
                    selectedRowKeys: selectedRows.map(row => row.id),
                    onChange(selectedRowKeys, _selectedRows, { type }) {
                      if (type === 'all') {
                        setSelectedRows(
                          _selectedRows.length === limit ? sortedCredits : [],
                        );
                      } else setSelectedRows(_selectedRows as Credit[]);
                    },
                  }
            }
            rowKey="id"
            pagination={{
              current: currentPage,
              pageSize: limit,
              onChange: handlePageChange,
              showSizeChanger: true,
            }}
            title={() =>
              isFacilitator || (
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: 'assign',
                        label: 'Assign Facilitator',
                        onClick: ({ key }) => handleAssignClick(key),
                        children: facilitatorsOptions,
                      },
                      {
                        key: 'unassign',
                        onClick: handleUnassignClick,
                        label: 'Unassign Facilitator',
                      },
                    ],
                  }}>
                  <Button>Actions</Button>
                </Dropdown>
              )
            }
          />
        </div>
      </Collapse>
      {contextHolder}
    </div>
  );
};
