import { AppThunk } from '../store/store';
import { actions } from './fetchProvider/fetch';
import {
  URL_ABATEMENT_BASELINES,
  URL_ABATEMENT_BASELINES_EDIT,
  URL_BCU_AIRPORTS,
  URL_BCU_FACILITATOR_UPDATE,
  URL_BCU_REGISTRATION,
  URL_COMPANY_CREDITS,
  URL_COMPANY_INCOMING_CREDITS,
  URL_CREDITS_APPROVE,
  URL_CREDITS_DENY,
  URL_CREDITS_RETIRE,
  URL_POS_FILE,
} from './urls/urls';
import { setBaseLines } from '../store/reducers/user/abatementBaselinesReducer';
import {
  setCredits,
  setIncomingCredits,
  updateCredit,
} from '../store/reducers/user/creditsReducer';
import {
  setAdminCredit,
  setAirports,
} from '../store/reducers/admin/bcuListReducer';

export const fetchBCURegistration =
  (registrationData: any): AppThunk<Promise<void>> =>
  () => {
    const data = new FormData();
    Object.entries(registrationData).forEach(([key, value]) => {
      data.append(key, value as any);
    });

    return actions.post<void>(URL_BCU_REGISTRATION, {
      payloads: data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };

export const fetchBaselines = (): AppThunk<Promise<void>> => dispatch => {
  return actions.get(URL_ABATEMENT_BASELINES).then((response: any) => {
    dispatch(setBaseLines(response.data.data));
  });
};

export const fetchBaselinesEdit =
  (id: number, data: any): AppThunk<Promise<void>> =>
  dispatch => {
    return actions
      .post(URL_ABATEMENT_BASELINES_EDIT.replace(':id', id.toString()), {
        payloads: data,
      })
      .then((response: any) => {
        dispatch(setBaseLines(response.data.data));
      });
  };

export const fetchBaselinesCreate =
  (data: any): AppThunk<Promise<void>> =>
  dispatch => {
    return actions
      .post(URL_ABATEMENT_BASELINES, {
        payloads: data,
      })
      .then((response: any) => {
        dispatch(setBaseLines(response.data.data));
      });
  };

export const fetchBaselinesDelete =
  (id: number): AppThunk<Promise<void>> =>
  dispatch => {
    return actions
      .delete(URL_ABATEMENT_BASELINES_EDIT.replace(':id', id.toString()))
      .then((response: any) => {
        dispatch(setBaseLines(response.data.data));
      });
  };

export const fetchCredits = () => (dispatch: any) => {
  return actions
    .get(URL_COMPANY_CREDITS)
    .then((resp: any) => dispatch(setCredits(resp.data.data)));
};

export const fetchIncomingCredits = () => (dispatch: any) => {
  return actions
    .get(URL_COMPANY_INCOMING_CREDITS)
    .then((resp: any) => dispatch(setIncomingCredits(resp.data.data)));
};

export const fetchIncomingCreditApprove = (id: string) => (dispatch: any) => {
  return actions
    .post(URL_CREDITS_APPROVE.replace(':id', id))
    .then((resp: any) => dispatch(setIncomingCredits(resp.data.data)));
};

export const fetchIncomingCreditReject = (id: string) => (dispatch: any) => {
  return actions
    .post(URL_CREDITS_DENY.replace(':id', id))
    .then((resp: any) => dispatch(setIncomingCredits(resp.data.data)));
};

export const fetchCreditRetire =
  ({ id, ...payloads }: any) =>
  () => {
    return actions.post(URL_CREDITS_RETIRE.replace(':id', id), { payloads });
  };

export const fetchDownloadPDF = (id: string) => () => {
  return actions
    .get(URL_POS_FILE.replace(':id', id), { responseType: 'blob' } as any)
    .then((resp: any) => {
      return resp.data;
    });
};
export const fetchAirports = (): AppThunk<Promise<any>> => dispatch => {
  return actions.get(URL_BCU_AIRPORTS).then((response: any) => {
    dispatch(setAirports(response.data.data));
  });
};

export const fetchUpdateCreditFacilitator =
  (
    creditId: number,
    action: string,
    facilitatorId?: string,
  ): AppThunk<Promise<any>> =>
  dispatch => {
    return actions
      .put(
        URL_BCU_FACILITATOR_UPDATE.replace(':id', creditId.toString()).replace(
          ':action',
          action,
        ),
        {
          payloads: {
            facilitatorId,
          },
        },
      )
      .then((response: any) => {
        dispatch(updateCredit(response.data.data));
        dispatch(setAdminCredit(response.data.data));
      });
  };
