import React, { useEffect, useState } from 'react';
import { useSearchParamsState } from '../components/Table/Pagination/PaginationHook';
import { fetchRetirementStatements } from '../../API/retirement-statements';
import { RETIREMENT_STATEMENTS_COLUMNS } from './models/columns.constant';
import { Search } from '../components/Table/Search/Search';

import './StatementsStyle.scss';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectUser } from '../../store/reducers/user/userReducer';
import { Role } from '../../structure/models/user/user';
import { DateQueryTypes, DateSelect } from '../components/Table/DateSelect';
import { Prisma, RetiredCredits } from '@prisma/client';
import AsyncTable from '../components/DefaultTable/async';
import { Column, SortOrder } from '../components/DefaultTable/types';
import {
  initPublicList,
  selectRetirementStatementsPublicList,
  setRetirementStatementsPublicList,
} from '../../store/reducers/user/retirementStatementsReducer';
import { isInTypes } from '../../helpers/types';

export type TRetirementStatementTableEntry = Prisma.RetiredCreditsGroupedGetPayload<{ include: { credits: true } }>;
export type TRetirementStatement = RetiredCredits;

export const SubTable = ({
  item,
  index,
  activeRows = {},
}: {
  item: TRetirementStatementTableEntry;
  index: number;
  activeRows: { [key: string]: boolean };
}) => {
  if (!item || !item.credits || item.credits.length === 0) {
    return null;
  }
  if (!activeRows[item.BCUID]) {
    return null;
  }

  return (
    <>
      {item.credits.map((i, iidx) => (
        <tr
          key={iidx}
          className={`c-table__tr c-table__tr--inner${
            iidx === 0 ? ' c-table__tr--inner-first' : ''
          }${
            iidx === item.credits.length - 1 ? ' c-table__tr--inner-last' : ''
          }`}>
          {RETIREMENT_STATEMENTS_COLUMNS.map((c, cidx) => (
            <td className="c-table__td" key={cidx}>
              <span className="c-table__text">
                {c.render && c.render(i as any, cidx)}
              </span>
            </td>
          ))}
        </tr>
      ))}
    </>
  );
};

export const Statements: React.FC = () => {
  const dispatch = useAppDispatch();
  const list = useAppSelector(selectRetirementStatementsPublicList);
  const user = useAppSelector(selectUser);
  const [activeRows, setActiveRows] = useState<{ [key: string]: boolean }>({});
  const [sortBy, setSortBy] = useState<{ key?: string; direction: SortOrder }>({
    key: 'BCUID',
    direction: 'desc',
  });
  const [startDate, setStartDate] = useSearchParamsState<string>(
    'startDate',
    '',
  ) as any;
  const [endDate, setEndDate] = useSearchParamsState<string>('endDate', '') as any;
  const [search, setSearch] = useSearchParamsState<string | null>(
    'search',
    null,
  );

  useEffect(() => {
    const params = {
      page: list.page,
      limit: list.limit,
      sortBy: sortBy.key,
      sortOrder: sortBy.direction,
      ...(startDate ? { startDate } : {}),
      ...(endDate ? { endDate } : {}),
      ...(search ? { search: search } : {}),
    };
    dispatch(fetchRetirementStatements(params));
  }, [
    list.page,
    list.limit,
    sortBy.key,
    sortBy.direction,
    startDate,
    endDate,
    search,
  ]);

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const onDateChange = ({ startDate, endDate }: DateQueryTypes) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const isDateFilterVisible = Boolean(
    !user || (user?.role && isInTypes(user.role, [Role.Admin, Role.SuperAdmin])),
  );

  const handleRowClicked = (item: TRetirementStatementTableEntry) => {
    setActiveRows({
      ...activeRows,
      [item.BCUID]: !activeRows[item.BCUID],
    });
  };

  const handleSortChange = ({
    column,
  }: {
    column: Column<TRetirementStatementTableEntry>;
  }) => {
    setSortBy(prevState => {
      let direction: SortOrder = 'asc';

      if (prevState.key === column.keyItem) {
        direction = prevState.direction === 'asc' ? 'desc' : 'asc';
      }

      return {
        ...prevState,
        direction,
        key: column.keyItem,
      };
    });
  };

  const onClear = () => setSearch(null);

  return (
    <div className="statements">
      <div className="statements__header">
        <h1 className="statements__header_text">
          Public BCU Retirement Statements
        </h1>
        <div className="statements__filters">
          {isDateFilterVisible && (
            <DateSelect 
              initialStartDate={startDate} 
              initialEndDate={endDate}
              onDateChange={onDateChange} 
            />
          )}
          <Search
            isClearable={Boolean(search)}
            onChange={onSearch}
            defaultValue={search ? search : ''}
            onClear={onClear}
          />
        </div>
      </div>

      <AsyncTable<any>
        isLoading={list.loading}
        showSearch={false}
        slots={{
          rowAfter: SubTable,
        }}
        slotProps={{
          head: {
            current: sortBy,
            onSortChange: handleSortChange,
          },
          rowAfter: { activeRows },
        }}
        onRowClicked={handleRowClicked}
        onLimitChange={limit =>
          dispatch(
            setRetirementStatementsPublicList({
              ...initPublicList,
              limit,
              page: 1,
            }),
          )
        }
        onPageChange={page =>
          dispatch(
            setRetirementStatementsPublicList({ ...initPublicList, page }),
          )
        }
        headers={RETIREMENT_STATEMENTS_COLUMNS}
        {...list}></AsyncTable>
    </div>
  );
};
