import React, { useEffect, useState } from 'react';
import { UserForm } from '../login/UserForm';
import { useAppDispatch } from '../../store/hooks';
import { Input } from '../components/Form/Input';
import { BaseButtonSubmit } from '../components/Buttons/BaseButtonSubmit/BaseButtonSubmit';
import { CheckBox } from '../components/Form/CheckBox/CheckBox';
import { Col, Row } from 'react-bootstrap';
import { Option } from '../components/Form/Select/Select';
import {
  CompanyType,
  RegistrationIdentifications,
} from '../../structure/models/company/company';

import { useNavigate } from 'react-router-dom';
import { DatePicker } from '../components/Form/DatePicker';
import classNames from 'classnames';
import RegistrationSelect from '../components/RegistarationForm/components/RegistrationSelect/RegistrationSelect';
import { Phone, PhoneValue } from '../components/Form/Phone';
import { AccountFields } from '../../structure/models/user/user';
import { accountFieldsValidator } from './helpers/validator';
import { accountDataProcessing } from './helpers/accountData';
import { InviteRegistration } from './invite-registration/InviteRegistration';
import { getFieldsErrors } from '../../helpers/getFieldsErrors';
import { fetchRegistration, fetchValidateInviteToken } from '../../API/login';
import { STATEMENTS } from '../../structure/routes/routes';
import './RegistrationStyle.scss';
import { ConfirmationPrompt } from '../components/ConfirmationPrompt';
import { DefaultSelect } from '../components/Form/DefaultSelect/DefaultSelect';
import FileUpload from '../components/FileUpload/FileUpload';
import { useNotification } from '../../hooks/useNotification';
import { COUNTRIES } from '../../constants/countries.constant';

const required = true;

const typeOptions: {
  value: CompanyType;
  label: string;
}[] = [
  {
    value: CompanyType.Supplier,
    label: 'Supplier',
  },
  {
    value: CompanyType.Intermediary,
    label: 'Intermediary',
  },
  {
    value: CompanyType.Airline,
    label: 'Transportation Service Provider (TSP)',
  },
  {
    value: CompanyType.Forwarder,
    label: 'Transport Logistics Provider (TLP)',
  },
  {
    value: CompanyType.Corporate,
    label: 'Corporate End-user',
  },
  {
    value: CompanyType.Auditor,
    label: 'Sustainability Auditor',
  },
  {
    value: CompanyType.Facilitator,
    label: 'Facilitator',
  }
];
const identificationOptions: {
  value: RegistrationIdentifications;
  label: string;
}[] = [
  {
    value: RegistrationIdentifications.RegistrationNumber,
    label: 'Registration number',
  },
  {
    value: RegistrationIdentifications.IdentificationNumber,
    label: 'Identification number',
  },
  {
    value: RegistrationIdentifications.VatIdentificationNumber,
    label: 'VAT identification number',
  },
  { value: RegistrationIdentifications.TaxRegistrationNumber, label: 'Tax registration number' },
  { value: RegistrationIdentifications.TradeRegisterNumber, label: 'Trade register number' },
];

export const flattenObject = (obj: any, prefix = '') =>
  Object.keys(obj).reduce((acc: any, k) => {
    const preL = prefix.length ? prefix + '[' : '';
    const preR = prefix.length ? ']' : '';
    if (typeof obj[k] === 'object') {
      Object.assign(acc, flattenObject(obj[k], preL + k + preR));
    } else {
      acc[preL + k + preR] = obj[k];
    }
    return acc;
  }, {});

export const Registration: React.FC = React.memo(() => {
  const toast = useNotification();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [companyLetter, setCompanyLetter] = useState<File>();
  const [, setErrors] = useState<string[]>([]);
  const [errorTexts, setErrorTexts] = useState<{ [key: string]: string }>({});
  const [isSendingAttempt, setSendingAttempt] = useState(false);
  const [accountTypeLabel, setAccountTypeLabel] = useState('');
  const [isConfirmationShow, setConfirmationShow] = useState(false);
  const [form, setForm] = useState<AccountFields>({
    companyName: '',
    accountType: '',
    inviteToken: '',
    registrationNumber: '',
    registrationIdentification: '',
    rsb: '',
    certificateStartDate: '',
    certificateEndDate: '',
    streetAddress: '',
    streetAddressLineTwo: '',
    zip: '',
    city: '',
    country: '',
    companyPhone_code: '',
    companyPhone_number: '',
    userPhone_code: '',
    userPhone_number: '',
    website: '',
    name: '',
    surname: '',
    email: '',
    position: '',
    agreement: false,
  });
  const urlData = window.location.search.slice(
    1,
    window.location.search.length,
  );

  const firstURLParam = urlData.split('&')[0];
  const isToken = firstURLParam.includes('token');
  const token = isToken ? isToken && firstURLParam.split('=')[1] : '';

  if (token) {
    // Validate invite token, if it's invalid, redirect to statements
    dispatch(fetchValidateInviteToken(token)).catch((e) => {
      toast.error({ message: 'Error', description: e?.response?.data?.message || 'Unknown error' });
      navigate(STATEMENTS);
    })
  }

  const isTypeSupplier = form.accountType === CompanyType.Supplier;
  const isTypeGeneral = form.accountType === CompanyType.Corporate;

  const accountValidator = accountFieldsValidator(form);

  const registrationIdentificationLabel = identificationOptions.find(
    (option) => option.value === form.registrationIdentification,
  )?.label || '';

  const onDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const onSelectorDataChange = (name: string, value: string) => {
    setForm({ ...form, [name]: value });
  };

  const onCompanyPhoneChange = ({ code, number }: PhoneValue) => {
    setForm({
      ...form,
      companyPhone_code: code ? code : form.companyPhone_code,
      companyPhone_number: number ? number : form.companyPhone_number,
    });
  };

  const onUserPhoneChange = ({ code, number }: PhoneValue) => {
    setForm({
      ...form,
      userPhone_code: code ? code : form.userPhone_code,
      userPhone_number: number ? number : form.userPhone_number,
    });
  };

  const onAccountTypeChange = (value: string, label: string) => {
    setAccountTypeLabel(label);
    onSelectorDataChange('accountType', value);
  };

  const onAgreementChange = () => {
    setForm({ ...form, agreement: !form.agreement });
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setSendingAttempt(true);

    const { keys } = getFieldsErrors(accountValidator, form);

    if (!companyLetter) {
      keys.push('signedCompanyLetter');
    }

    if (!keys.length) {
      const data = accountDataProcessing(form);
      const formData = new FormData();
      const flattenData = flattenObject(data);

      Object.keys(flattenData).forEach(key => {
        if (flattenData[key] !== undefined) {
          formData.set(key, (flattenData as any)[key]);
        }
      });

      if (companyLetter) {
        formData.set('signedCompanyLetter', companyLetter);
      }

      setErrorTexts({});
      dispatch(fetchRegistration(formData))
        .then(() => {
          setConfirmationShow(true);
        })
        .catch(e => {
          const message = e?.response?.data?.message;
          toast.error({ message: 'Error', description: <span>{Array.isArray(message) ? <ul style={{margin: 0, padding: 0}}>{message.map((v) => <li>{v}</li>)}</ul> : message || 'Unknown error'}</span> });
        });
    }
  };

  const onConfirmWindowClose = () => {
    setConfirmationShow(false);
    navigate(STATEMENTS);
  };

  useEffect(() => {
    if (isSendingAttempt) {
      const { keys, errorTexts } = getFieldsErrors(accountValidator, form);

      if (!companyLetter) {
        keys.push('signedCompanyLetter');
        errorTexts['signedCompanyLetter'] = 'Field is required';
      }

      setErrors(keys);
      setErrorTexts(errorTexts);
    }
  }, [form, isSendingAttempt, companyLetter]);

  const subtitle = isToken
    ? 'Complete the details for your RSB Registry account'
    : 'Apply for an RSB Registry account';

  return (
    <>
      <div className="registration">
        <div className="registration__welcome">
          <h2>Welcome!</h2>
          <p className="under_h">{subtitle}</p>
        </div>

        <div className="registration__form_data">
          {isToken ? (
            <InviteRegistration token={token} />
          ) : (
            <form onSubmit={onSubmit}>
              <Row>
                <Col>
                  <div className="title">Company data</div>
                </Col>
              </Row>
              <div className="form_block">
                <Row>
                  <Col>
                      <Input
                      id="companyName"
                      label="Company name"
                      placeholder="Company name"
                      name="companyName"
                      type="text"
                      required={required}
                      onChange={onDataChange}
                      error={errorTexts['companyName']}
                    />
                  </Col>
                </Row>
                <Row
                  className={classNames({ registration__RSB: isTypeSupplier })}>
                  <Col>
                    <div className="registration__select">
                      <RegistrationSelect
                        id="accountType"
                        name="accountType"
                        label="Registry account type"
                        placeholder="Registry account type"
                        value={accountTypeLabel}
                        required
                        options={typeOptions}
                        onChange={(value, label) =>
                          onAccountTypeChange(value, label)
                        }
                        errorVisible={true}
                        error={errorTexts['accountType']}
                      />
                    </div>
                  </Col>
                  {isTypeSupplier && (
                    <>
                      <Col md={6}>
                        <Input
                          id="rsbPONumber"
                          label="RSB PO number"
                          placeholder="RSB PO number"
                          name="rsb"
                          type="text"
                          required={required}
                          onChange={onDataChange}
                          error={errorTexts['rsb']}
                        />
                      </Col>
                      <Col md={6}>
                        <DatePicker
                          id="certificateStartDate"
                          label="Certificate validity start date"
                          placeholder="Certificate validity start date"
                          name="certificateStartDate"
                          max={form.certificateEndDate}
                          required={required}
                          onChange={onDataChange}
                          error={errorTexts['certificateStartDate']}
                        />
                      </Col>
                      <Col md={6}>
                        <DatePicker
                          id="certificateEndDate"
                          label="Certificate validity end date"
                          placeholder="Certificate validity end date"
                          name="certificateEndDate"
                          min={form.certificateStartDate}
                          required={required}
                          onChange={onDataChange}
                          error={errorTexts['certificateEndDate']}
                        />
                      </Col>
                    </>
                  )}
                </Row>
                {isTypeGeneral && (
                  <Row>
                    <Input
                      id="inviteToken"
                      label="Token"
                      placeholder="Token"
                      name="inviteToken"
                      type="text"
                      required={required}
                      onChange={onDataChange}
                      error={errorTexts['inviteToken']}
                    />
                  </Row>
                )}
                <Row>
                  <Col md={6}>
                    <div className="registration__select">
                      <RegistrationSelect
                        id="registrationIdentification"
                        value={registrationIdentificationLabel}
                        name="registrationIdentification"
                        placeholder="Company legal identification"
                        label="Company legal identification"
                        required
                        options={identificationOptions}
                        onChange={value =>
                          onSelectorDataChange(
                            'registrationIdentification',
                            value,
                          )
                        }
                        errorVisible={true}
                        error={errorTexts['registrationIdentification']}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <Input
                      id="registrationNumber"
                      label="Company legal identification number"
                      placeholder="Company legal identification number"
                      name="registrationNumber"
                      type="text"
                      required={required}
                      onChange={onDataChange}
                      error={errorTexts['registrationNumber']}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input
                      id="streetAddress"
                      name="streetAddress"
                      label="Street address"
                      placeholder="Street address"
                      required
                      onChange={onDataChange}
                      error={errorTexts['streetAddress']}
                    />
                  </Col>
                  <Col>
                    <Input
                      id="streetAddressLineTwo"
                      label="Street address line 2"
                      placeholder="Street address line 2"
                      name={'streetAddressLineTwo'}
                      onChange={onDataChange}
                      error={errorTexts['streetAddressLineTwo']}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <Input
                      id="zip"
                      name={'zip'}
                      label="ZIP"
                      placeholder="ZIP"
                      required
                      onChange={onDataChange}
                      error={errorTexts['zip']}
                    />
                  </Col>
                  <Col md={3}>
                    <Input
                      id="city"
                      name="city"
                      label="City"
                      placeholder="City"
                      required
                      onChange={onDataChange}
                      error={errorTexts['city']}
                    />
                  </Col>
                  <Col md={6}>
                    <div className="company-address__select">
                      <DefaultSelect
                        id="country"
                        required
                        isSearchable
                        inputId="country"
                        name="country"
                        label="Country"
                        placeholder="Country"
                        options={COUNTRIES as any}
                        value={form.country as any}
                        error={errorTexts['country']}
                        onChange={(option: Option) =>
                          onSelectorDataChange('country', option.value)
                        }
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Phone
                      id="companyPhone"
                      name="companyPhone"
                      phoneNumberLabel="Phone"
                      phoneNumberPlaceholder="Phone"
                      required
                      onChange={onCompanyPhoneChange}
                      error={
                        errorTexts['companyPhone_number'] ||
                        errorTexts['companyPhone_code']
                      }
                    />
                  </Col>
                  <Col>
                    <Input
                      id="website"
                      name="website"
                      label="Website"
                      placeholder="Website"
                      onChange={onDataChange}
                      error={errorTexts['website']}
                    />
                  </Col>
                </Row>
              </div>

              <div className="title">Account owner data</div>

              <div className="form_block">
                <UserForm
                  required={required}
                  errorTexts={errorTexts}
                  onDataChange={onDataChange}
                  onPhoneChange={onUserPhoneChange}
                />

                <FileUpload
                  id="signedCompanyLetter"
                  name="signedCompanyLetter"
                  label="Upload signed company letter"
                  onFileUpload={setCompanyLetter}
                  error={errorTexts['signedCompanyLetter']}
                  accept="application/pdf"
                  required
                />

                <small>See document "RSB Registry Declaration of Commitment" under "Resources" in the "Information" tab</small>
              </div>

              <div className="form_block">
                <CheckBox
                    id="agreement"
                    name="agreement"
                    checked={form.agreement}
                    onClick={onAgreementChange}
                    error={errorTexts['agreement']}>
                  <span>
                    I accept the General&nbsp;
                    <a
                        className="c-terms-link"
                        rel="noreferrer nofollow noopener"
                        target="_blank"
                        href="https://rsb.org/wp-content/uploads/2023/10/RSB-BC-Registry-Terms-and-Conditions.pdf">
                      Terms & Conditions
                    </a>
                    &nbsp;and Privacy Policy
                  </span>
                </CheckBox>
              </div>

              <BaseButtonSubmit active>Submit</BaseButtonSubmit>
            </form>
          )}
        </div>
      </div>
      <ConfirmationPrompt
          show={isConfirmationShow}
          centralized
          title="Confirm your email address"
          isFalseButton={false}
          onClosed={onConfirmWindowClose}>
        Thank you for providing us with your details! An email has been sent to
        you, please follow the link provided to confirm your application.
        <br />
        Thank you!
      </ConfirmationPrompt>
    </>
  );
});
