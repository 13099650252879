import { FC, useMemo } from 'react';
import { PageWithTabsLayout } from '../../layouts/PageWithTabsLayout';
import { TABS } from './models/tabs.constant';
import { useNavigate } from 'react-router-dom';
import { ViewElement } from '../ViewElement/ViewElement';
import { selectCreditDetail } from '../../../store/reducers/user/creditsReducer';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import './CreditInfoStyle.scss';
import { selectUserAccountData } from '../../../store/reducers/user/userReducer';
import { isInTypes } from '../../../helpers/types';
import { CompanyType } from '@prisma/client';
import { fetchCompanyInfo } from '../../../API/company';
import { BCU_DETAILS_ROUTES } from '../../../structure/routes/routes';

export const CreditInfo: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const credit = useAppSelector(selectCreditDetail);
  const account = useAppSelector(selectUserAccountData);

  if (!account) {
    dispatch(fetchCompanyInfo());
  }

  const goBack = () => navigate(-1);

  const tabAccessibility = useMemo(() => ({
    bcuValues: isInTypes(account?.type, [CompanyType.Supplier, CompanyType.Auditor]),
    bcuFacilitator: !isInTypes(account?.type, [CompanyType.Facilitator]),
  }), [account?.type]);

  const tabs = useMemo(() => {
    return TABS.filter(tab => {
      if (!tabAccessibility.bcuValues && tab.path === BCU_DETAILS_ROUTES.BCU_VALUES) {
        return false;
      }
      if (!tabAccessibility.bcuFacilitator && tab.path === BCU_DETAILS_ROUTES.FACILITATOR) {
        return false;
      }
      return true;
    });
  }, [tabAccessibility]);

  return (
    <div className="dashboard-bcu-info">
      <div className="dashboard-bcu-info__back" onClick={goBack}>
        <span className="material-icons-outlined">arrow_back_ios</span>
        Back
      </div>
      {credit && (
        <>
          <div className="dashboard-bcu-info__header">
            <div className="dashboard-bcu-info__header__info">
              <ViewElement
                label="BCU ID"
                description={credit.BCUID}></ViewElement>
            </div>
          </div>
          <PageWithTabsLayout tabs={tabs} />
        </>
      )}
    </div>
  );
};
