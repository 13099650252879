import { Credit } from '@prisma/client';
import { ColumnsType } from 'antd/es/table';

import { formatDate } from '../../../core/models/contstants/format-date';
import { limitOfNumbers } from '../../../../helpers/limitOfNumbers';

const formatVolume = (volume: number) =>
  `${volume ? limitOfNumbers(volume) : 0} BCU`;

export const getColumns = (
  onTitleClick: (item: any) => void,
  isFacilitator: boolean,
): ColumnsType<Credit> => [
  {
    title: 'BCU ID',
    key: 'BCUID',
    render: item => <span onClick={onTitleClick}>{item.BCUID}</span>,
    className: 'cursor-pointer',
    onCell: item => ({
      onClick: () => onTitleClick(item),
    }),
  },
  {
    title: 'Product',
    key: 'product',
    render: item => item.productType,
  },
  {
    title: 'Feedstock',
    key: 'feedstock',
    render: item => item.rawMaterial,
  },
  {
    title: 'Scheme',
    key: 'scheme',
    render: item => item.certificationScheme,
  },
  {
    title: 'Date of registration',
    key: 'registrationApproveDate',
    render: item => formatDate(item.registrationApproveDate),
  },
  {
    title: (
      <span>
        CO<sub>2</sub>eq reduction (t)
      </span>
    ),
    key: 'tonnesOfCO2eq',
    render: item => (item.tonnesOfCO2eq || 0).toFixed(3),
  },
  {
    title: 'Volume',
    key: 'volume',
    render: item => formatVolume(item.volume),
  },
  isFacilitator
    ? {
        title: 'BCU Holder',
        key: 'bcuHolder',
        render: item => item.bcuHolder || '',
      }
    : {
        title: 'Facilitator',
        key: 'facilitator',
        render: item => item.facilitator?.name || '',
      },
];
