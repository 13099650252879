import { BCU_DETAILS_ROUTES } from '../../../../structure/routes/routes';

export const TABS = [
  {
    title: 'BCU Values',
    path: BCU_DETAILS_ROUTES.BCU_VALUES,
  },
  {
    title: 'BCU Info',
    path: BCU_DETAILS_ROUTES.BCU_INFO,
  },
  {
    title: 'Scope 1 Info',
    path: BCU_DETAILS_ROUTES.SCOPE1,
  },
  {
    title: 'Scope 3 Info',
    path: BCU_DETAILS_ROUTES.SCOPE3,
  },
  {
    title: 'Facilitator',
    path: BCU_DETAILS_ROUTES.FACILITATOR,
  }
];
