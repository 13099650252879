import { Prisma, CompanyType } from '@prisma/client';
import { Switch } from 'antd';
import { ColumnType } from 'antd/es/table/interface';

import Status from '../StatusComponent';
import { Column } from '../DefaultTable/types';
import { COMPANY_TYPE_NAMES } from '../../../structure/models/company/company';

export type Contact = Prisma.ContactGetPayload<{
  include: {
    to: {
      select: {
        id: true;
        name: true;
        type: true;
        country: true;
        status: true;
      };
    };
  };
}>;

export const getContactColumns = (
  onRemove: (id: string) => void,
): Column<Contact>[] => {
  const onItemRemove = (
    id: string,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    onRemove(id);
  };

  return [
    {
      title: 'Company name',
      // keyItem: 'name',
      render: item => item.to?.name,
    },
    {
      title: 'Country',
      // keyItem: 'country',
      render: item => item.to?.country,
    },
    {
      title: 'Company type',
      // keyItem: 'type',
      render: item => (item?.to?.type ? COMPANY_TYPE_NAMES[item.to?.type] : ''),
    },
    {
      title: 'Account status',
      // keyItem: 'status',
      render: (item: Contact) => <Status statusOfComponent={item.to?.status} />,
    },
    {
      title: 'Actions',
      // keyItem: 'status',
      render: item => (
        <button
          title="Remove contact"
          style={{
            border: 'none',
            outline: 'none',
            background: 'transparent',
            cursor: 'pointer',
          }}
          onClick={e => onItemRemove(item.to?.id?.toString(), e)}>
          <span
            className="material-icons-outlined"
            style={{ fontSize: '18px' }}>
            delete
          </span>
        </button>
      ),
    },
  ];
};

const createSwitchColumn = (
  isVisible: boolean,
  isCorporate: boolean,
  isTransportCompany: boolean,
  isRelatedTransportCompany: boolean,
  item: Contact,
  switchChecked: boolean,
  switchValue: boolean,
  switchOnChange: (isChecked: boolean) => void,
) => {
  if (!isVisible) return null;

  const { type } = item.to || {};
  const isEligibleCompany =
    type === CompanyType.Forwarder ||
    type === CompanyType.Airline ||
    type === CompanyType.Corporate;

  if (!isEligibleCompany) return null;
  if (isTransportCompany && isRelatedTransportCompany) return null;

  return isCorporate ? (
    <div className="d-flex justify-content-cent">
      <Switch
        onChange={switchOnChange}
        defaultChecked={switchChecked}
        checked={switchValue}
      />
    </div>
  ) : switchValue ? (
    'Yes'
  ) : (
    'No'
  );
};

export const CONTACT_LIST_COLUMNS = (
  onRemove: (id: string) => void,
  companyCertifiedFacilitators: string[] | undefined,
  handleSwitchFacilitator: (id: string, isChecked: boolean) => void,
  handleAllowOnBehalfSwitch: (id: string, isChecked: boolean) => void,
  handlePublicAnonymitySwitch: (id: string, isChecked: boolean) => void,
  isUserSuspended: boolean,
  isFacilitator: boolean,
  isAuditor: boolean,
  isCorporate: boolean,
  isShowPermissionCols: boolean,
  isTransportCompany: boolean,
): ColumnType<Contact>[] => {
  const onItemRemove = (
    id: string,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    onRemove(id);
  };

  return [
    {
      title: 'Company name',
      render: item =>
        item.isCreatedByFacilitator
          ? 'Facilitator managed account'
          : item.to?.name,
    },
    {
      title: 'Country',
      render: item =>
        item.isCreatedByFacilitator ? 'Anonymous' : item.to?.country,
    },
    {
      title: 'Company type',
      render: item => COMPANY_TYPE_NAMES[item.to?.type],
    },
    isShowPermissionCols
      ? {
          title: 'Public anonymity',
          render: item =>
            createSwitchColumn(
              isShowPermissionCols,
              isCorporate,
              isTransportCompany,
              item.to?.type === CompanyType.Forwarder ||
                item.to?.type === CompanyType.Airline,
              item,
              false,
              item?.isPublicAnonymity,
              isChecked => handlePublicAnonymitySwitch(item.to?.id, isChecked),
            ),
        }
      : {},
    isShowPermissionCols
      ? {
          title: 'Permission on behalf',
          render: item =>
            createSwitchColumn(
              isShowPermissionCols,
              isCorporate,
              isTransportCompany,
              item.to?.type === CompanyType.Forwarder ||
                item.to?.type === CompanyType.Airline,
              item,
              false,
              item?.isAllowOnBehalf,
              isChecked => handleAllowOnBehalfSwitch(item.to?.id, isChecked),
            ),
        }
      : {},
    {
      title: 'Account status',
      render: (item: Contact) => <Status statusOfComponent={item.to?.status} />,
    },
    !isFacilitator && !isAuditor
      ? {
          title: 'Permit on behalf',
          render: item =>
            item.to?.type === CompanyType.Facilitator && (
              <div className="d-flex justify-content-cent">
                <Switch
                  onChange={(isChecked: boolean) =>
                    handleSwitchFacilitator(item.to?.id, isChecked)
                  }
                  defaultChecked={false}
                  checked={companyCertifiedFacilitators?.includes(item.to.id)}
                />
              </div>
            ),
        }
      : {},
    {
      title: 'Actions',
      render: item => (
        <button
          disabled={isUserSuspended}
          title="Remove contact"
          style={{
            border: 'none',
            outline: 'none',
            background: 'transparent',
            cursor: 'pointer',
          }}
          onClick={e => onItemRemove(item.to?.id?.toString(), e)}>
          <span
            className="material-icons-outlined"
            style={{ fontSize: '18px' }}>
            delete
          </span>
        </button>
      ),
    },
  ];
};
